@font-face {
  font-family: croogla;
  src: url(./assets/croogla.woff2);
}
@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  text-align: center;
  font-family: croogla;
}
#logo {
  max-width: 100%;
  height: auto;
  width: clamp(300px, 70vw, 600px); /* Significantly increased logo size */
  margin-bottom: 2rem; /* Add space below the logo */
}
body {
  background-image: url(./assets/waves.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  overflow-x: hidden;
}

.parent {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Changed to flex-start to push content to the top */
  padding: 2rem 1rem;
}

.div1, .div2, .div3, .div4 {
  width: 100%;
}

.div2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem; /* Add space above the content */
}

#soon {
  max-width: 100%;
  height: auto;
  margin-bottom: 1rem;
}

.blue {
  color: #003CEF;
}

.tagline {
  font-size: clamp(24px, 5vw, 48px);
  margin: 1rem 0;
}

.follow, .url {
  margin: 0.5rem 0;
}

.custom_input {
  width: 100%;
  max-width: 600px;
  display: flex;
  align-items: center;
  position: relative;
  margin: 1rem 0;
}

.input {
  font-size: clamp(18px, 3vw, 28px);
  text-align: left;
  padding: 15px;
  width: 100%;
  height: 60px;
  outline: none;
  background: #FFFFFF;
  color: #000000;
  border: 6px solid rgba(0, 60, 239, 0.3);
  border-radius: 18px;
  box-shadow: 3px 3px 2px 0px #E2E2E2;
  transition: .3s ease;
}

.input:focus {
  background: #F2F2F2;
  border: 6px solid rgb(0, 60, 239);
}

.input::placeholder {
  color: #adadad;
}

.svg_icon {
  position: absolute;
  right: 20px;
  fill: #4660DC;
  width: 24px;
  height: 24px;
}

.submitButon, .logButon {
  box-shadow: 0px 4px 0px 0px #000000;
  background-color: #0004ff;
  border-radius: 28px;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: clamp(18px, 3vw, 22px);
  padding: 15px 30px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #2f6627;
  margin: 0.5rem;
}

.submitButon:hover, .logButon:hover {
  background-color: #0004e8;
}

.submitButon:active, .logButon:active {
  position: relative;
  top: 1px;
}

.container-input {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.thumb {
  position: fixed;
  bottom: 0;
  right: 0;
  max-width: 20%;
  height: auto;
}

@media screen and (max-width: 768px) {
  .parent {
    padding: 1rem;
  }

  #soon {
    max-width: 250px;
  }

  .custom_input {
    max-width: 100%;
  }

  .thumb {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  body {
    overflow-y: auto;
  }

  .parent {
    padding: 0.5rem;
  }

  #logo {
    width: clamp(200px, 90vw, 400px); /* Adjusted for smaller screens */
  }

  .submitButon, .logButon {
    padding: 12px 24px;
  }

  #subtext {
    font-size: 16px;
  }
}

@media screen and (max-width: 384px) {
  .parent {
    padding: 0.25rem;
  }

  #logo {
    width: clamp(180px, 95vw, 350px); /* Further adjusted for very small screens */
  }

  .submitButon, .logButon {
    padding: 10px 20px;
  }

  #subtext {
    font-size: 14px;
  }
}
