body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.rootypatooty {
  display: flex;
  min-height: 100vh;
}

/* Main content layout with sidebar */
.content {
  flex-grow: 1;
  margin-left: 5rem; /* Matches the default sidebar width */
  transition: margin-left 0.3s ease-in-out;
  padding: 20px;
  overflow-x: hidden;
}

/* Adjust content margin when sidebar is expanded */
.sidebar.expanded ~ .content {
  margin-left: 16rem;
}

/* Mobile adjustments */
@media (max-width: 1024px) {
  .content {
    margin-left: 0;
    padding: 16px;
  }
  
  .sidebar.expanded ~ .content {
    margin-left: 0;
  }
}